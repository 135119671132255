export const backendUrl = "https://jainjobsbe.techjainsupport.co.in/api/";
export const Homepage = "https://jainjobsbe.techjainsupport.co.in";
export const ImageBackend = "https://jainjobsbe.techjainsupport.co.in/";

// export const backendUrl = "http://localhost:8001/api/";
// export const Homepage = "http://localhost:3000";
// export const ImageBackend = "http://localhost:8001/";

export const EncryptionKey = "Fnm@RegistrationKey#";
export const GOOGLECLIENTID = "747910821149-2htccc237lha23kvpgr07m44u1skuvv4.apps.googleusercontent.com";
export const GOOGLECLIENTSECRET = "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";




