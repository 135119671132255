export const GET_BLOG_POST = "GET_BLOG_POST";
export const DELETE_BLOG_POST = "DELETE_BLOG_POST";
export const GET_SKILL = "GET_SKILLS";
export const JOB_CATEGORY = "JOB_CATEGORY";
export const GET_JOB_INDUSTRY = "GET_JOB_INDUSTRY";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_CONTACT = "GET_CONTACT";
export const GET_CITY = "GET_CITY";
export const GET_PLAN = "GET_PLAN";
export const GET_USER = "GET_USER";
export const JOBS_AVAILABLE = "JOBS_AVAILABLE";
export const GET_STORY = "GET_STORY";
export const GET_SPECIAL_SERVICE = "GET_SPECIAL_SERVICE";
export const OWN_PLANS = "OWN_PLANS";
export const GET_QUESTIONS = "GET_QUESTIONS";